import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IKeyPhraseBlock, IPost, IQuoteBlock, IText, ITextBlock, ISliderBlock, IListBlock } from '../../../../types/strapi/posts';
// import { formatDateTextShort } from '../../../../utils';
import GridWrapper from '../../../grid-wrapper';
import { Picture } from '../../../Picture';
import Text from '../../../text';
import Share from '../Share';
import Slider from '../Slider';

import QuotesIcon from './assets/quotes.inline.svg';

import './index.css';

const mainClass = 'content';

interface IProps {
    post: IPost
    // posts: Array<IPost> | null
}

const Content = ({ post }: IProps) => {
    const cn = useClassnames();

    const [popupActive, setPopupActive] = useState(false);

    const formatTextBlock = (paragraphs: Array<IText>) => {
        return paragraphs.map((paragraph: IText, index) => {
            return (
                <div
                    key={`block-text-${index + 1}`}
                    className={cn(`${mainClass}__texts-item ${mainClass}__text-item_${index + 1}`)}
                >
                    <Text
                        className={cn(`${mainClass}__texts-text`)}
                        size={3}
                        dangerouslySetInnerHTML={{ __html: paragraph.text }}
                    />
                </div>
            );
        });
    };

    // const listNews = posts?.map((news, index) => {
    //     return (
    //         <li key={`news-${index + 1}`} className={cn(`${mainClass}__item ${mainClass}__item_${index + 1}`)}>
    //             <a href={`/news/${news.id}`}>
    //                 <div className={`${mainClass}__status`}>
    //                     <Text
    //                         className={cn(`${mainClass}__status-type ${mainClass}__status-type_${news.type.value}`)}
    //                         size={5}
    //                         dangerouslySetInnerHTML={{ __html: news.type.text }}
    //                     />
    //                     <Text
    //                         className={cn(`${mainClass}__status-date`)}
    //                         size={5}
    //                         dangerouslySetInnerHTML={{ __html: formatDateTextShort(news.date) }}
    //                     />
    //                 </div>
    //                 <Text
    //                     className={cn(`${mainClass}__item-name`)}
    //                     size={3}
    //                     dangerouslySetInnerHTML={{ __html: news.title }}
    //                 />
    //             </a>
    //         </li>
    //     );
    // });

    return (
        <GridWrapper className={cn(`${mainClass}__wrapper`)}>
            <div className={cn(`${mainClass}__share ${mainClass}__share_top`)}>
                <div className={cn(`${mainClass}__share-btn-block`)}>
                    <button
                        className={cn(`${mainClass}__share-btn`)} onClick={() => {
                            setPopupActive((prev) => !prev);
                        }}
                    >
                        <Text
                            className={cn(`${mainClass}__share-btn-text`)}
                            size={4}
                            dangerouslySetInnerHTML={{ __html: 'Поделиться' }}
                        />
                    </button>
                    <CSSTransition
                        in={popupActive} timeout={600} classNames="share" unmountOnExit={true}
                        mountOnEnter={true}
                    >
                        <Share setActive={setPopupActive} />
                    </CSSTransition>
                </div>
            </div>

            {/* <ul className={cn(`${mainClass}__list-news`)}>*/}
            {/*    {listNews}*/}
            {/* </ul>*/}

            {post.contentBlock[0].__typename !== 'ComponentPostText' ? (
                <div className={cn(`${mainClass}__block`)}>
                    <div className={cn(`${mainClass}__texts`)}>
                        <div className={cn(`${mainClass}__texts-item ${mainClass}__text-item_1`)}>
                            <Text
                                className={cn(`${mainClass}__texts-text`)}
                                size={1}
                                dangerouslySetInnerHTML={{ __html: post.leadParagraph }}
                            />
                        </div>
                    </div>
                </div>
            )
                : null}

            {post.contentBlock.map((block: ITextBlock | IQuoteBlock | IKeyPhraseBlock | ISliderBlock, index: number) => {
                if(block.__typename === 'ComponentPostText') {
                    const blockData = block as ITextBlock;
                    return (
                        <div key={index} className={cn(`${mainClass}__block`)}>
                            {index === 0 ? (
                                <div className={cn(`${mainClass}__block`)}>
                                    <div className={cn(`${mainClass}__texts`)}>
                                        <div className={cn(`${mainClass}__texts-item ${mainClass}__text-item_1`)}>
                                            <Text
                                                className={cn(`${mainClass}__texts-text`)}
                                                size={1}
                                                dangerouslySetInnerHTML={{ __html: post.leadParagraph }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                                : null}
                            <div className={cn(`${mainClass}__texts`)}>
                                {formatTextBlock(blockData.paragraphs)}
                            </div>
                        </div>
                    );
                } else if(block.__typename === 'ComponentPostQuote') {
                    const blockData = block as IQuoteBlock;
                    return (
                        <div key={index} className={cn(`${mainClass}__quotes`)}>
                            <div className={`${mainClass}__quotes-item`}>
                                <div className={`${mainClass}__quotes-person`}>
                                    <div className={`${mainClass}__quotes-person-icon`}>
                                        <QuotesIcon />
                                    </div>
                                    {blockData.image
                                        && (
                                            <div className={`${mainClass}__quotes-person-photo`}>
                                                <Picture
                                                    image={blockData.image}
                                                    alt={`${blockData.name} ${blockData.surname}`}
                                                />
                                            </div>
                                        )}
                                    <Text
                                        className={cn(`${mainClass}__quotes-person-name`)}
                                        size={3}
                                        dangerouslySetInnerHTML={{ __html: `${blockData.name} ${blockData.surname}` }}
                                    />
                                    <Text
                                        className={cn(`${mainClass}__quotes-person-post`)}
                                        size={4}
                                        dangerouslySetInnerHTML={{ __html: blockData.description }}
                                    />
                                </div>
                                <div className={`${mainClass}__quotes-block`}>
                                    <Text
                                        className={cn(`${mainClass}__quotes-block-text`)}
                                        size={1}
                                        dangerouslySetInnerHTML={{ __html: blockData.text }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                } else if(block.__typename === 'ComponentPostKeyPhrase') {
                    const blockData = block as IKeyPhraseBlock;
                    return (
                        <div key={index} className={cn(`${mainClass}__key-phrase`)}>
                            <div className={`${mainClass}__key-phrase-item`}>
                                <div className={`${mainClass}__key-phrase-block`}>
                                    <Text
                                        className={cn(`${mainClass}__key-phrase-block-text`)}
                                        size={1}
                                        dangerouslySetInnerHTML={{ __html: blockData.phrase }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                } else if(block.__typename === 'ComponentPostSlider') {
                    const blockData = block as ISliderBlock;
                    return (
                        <div key={index} className={cn(`${mainClass}__slider`)}>
                            <Slider index={index} items={blockData.slider_items} />
                        </div>
                    );
                } else if(block.__typename === 'ComponentPostList') {
                    const blockData = block as IListBlock;

                    return <div className={`${mainClass}__block`}>
                        <ul key={index} className={`${mainClass}__list`}>
                            {blockData.list.map((item, itemIndex) => {
                                return <li key={itemIndex}>{item.text}</li>;
                            })}
                        </ul>
                    </div>;
                }

                return null;
            })}

            <div className={cn(`${mainClass}__share ${mainClass}__share_bottom`)}>
                <div className={cn(`${mainClass}__share-btn-block`)}>
                    <button
                        className={cn(`${mainClass}__share-btn`)} onClick={() => {
                            setPopupActive((prev) => !prev);
                        }}
                    >
                        <Text
                            className={cn(`${mainClass}__share-btn-text`)}
                            size={4}
                            dangerouslySetInnerHTML={{ __html: 'Поделиться' }}
                        />
                    </button>
                    <CSSTransition
                        in={popupActive} timeout={600} classNames="share" unmountOnExit={true}
                        mountOnEnter={true}
                    >
                        <Share setActive={setPopupActive} />
                    </CSSTransition>
                </div>
                {/*                <div className={`${mainClass}__tags`}>
                    {post.tags.map(({ text, id }, index) => {
                        return (
                            <a key={index} href={`/blog?tag=${id}`}>
                                <Text
                                    key={index}
                                    className={cn(`${mainClass}__tags-item`)}
                                    size={4}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                            </a>
                        );
                    })}
                </div>*/}
            </div>

        </GridWrapper>
    );
};

export default Content;
