import React, { CSSProperties, useMemo } from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { TBlogPost } from '../../../../types/strapi/posts';
import { formatDateTextShort } from '../../../../utils';
import { Picture } from '../../../Picture';
import Text from '../../../text';

interface IItemProps {
    post: TBlogPost,
    main?: boolean,
    rootClassName: string
}

const ItemContent = ({ post, rootClassName, main = false }: IItemProps) => {
    const cn = useClassnames();

    const tags = (obj: TBlogPost, mod: string) => (
        <ul className={`${rootClassName}__item-tags ${rootClassName}__item-tags_${mod}`}>
            {/* <div className={`${rootClassName}__item-tags-bg`}>*/}
            {/*    {obj.tags.length < 3 ? <TagsBgMin /> : <TagsBg />}*/}
            {/* </div>*/}
            {obj.tags.map(({ text, id }, index) => (
                <li key={index}>
                    <a href={`/blog?tag=${id}`}>
                        <Text
                            className={cn(`${rootClassName}__item-tags-item`)}
                            size={5}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </a>
                </li>
            ))}
        </ul>
    );

    const gradientColor = useMemo((): CSSProperties => {
        return {
            color: post.type.colorHex
        };
    }, [post]);

    return (
        <React.Fragment>
            <div className={cn(`${rootClassName}__item-img`, {
                [`${rootClassName}__item-img_${post.type.value}`]: post.type.value
            })}
            >
                <a href={`${post.code ? `/news/${post.code}` : '#'}`}>
                    <Picture image={post.previewImage} alt={post.previewImage.alt ?? 'item-img'} />
                    {post.author && (
                        <div className={`${rootClassName}__item-person`}>
                            <div className={`${rootClassName}__item-person-img`}>
                                <Picture image={post.author.image} alt={post.author.image.alt ?? 'item-img'} />
                            </div>
                        </div>
                    )}
                </a>
                {tags(post, 'mob')}
            </div>
            <a href={`${post.code ? `/news/${post.code}` : '#'}`} className={`${rootClassName}__item-info`}>
                {tags(post, 'desk')}
                <div className={`${rootClassName}__gradient`} style={gradientColor} />
                <div className={`${rootClassName}__item-info-time`}>
                    <Text
                        className={cn(`${rootClassName}__info-type ${rootClassName}__info-type_${post.type.value}`)}
                        size={5}
                        colorHex={post.type.colorHex}
                        dangerouslySetInnerHTML={{ __html: post.type.text }}
                    />
                    <Text
                        className={cn(`${rootClassName}__info-date`)}
                        size={5}
                        dangerouslySetInnerHTML={{ __html: formatDateTextShort(post.date) }}
                    />
                    <Text
                        className={cn(`${rootClassName}__info-time`)}
                        size={5}
                        dangerouslySetInnerHTML={{ __html: `${post.readingTime} чтения` }}
                    />
                </div>
                <Text
                    className={cn(`${rootClassName}__info-name`)}
                    size={main ? 2 : 3}
                    dangerouslySetInnerHTML={{ __html: post.title }}
                />
            </a>
        </React.Fragment>
    );
};

export default ItemContent;